import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import CTA from '../components/CTA'
import WRAL from '../images/WRAL.png'
import WILM from '../images/WILM.png'
import SUNRISE from '../images/SunriseBroadcasting.png'
import MIX from '../images/MIX.png'
import FOX from '../images/Fox.png'
import THEFAN from '../images/99.9TheFan.png'
import tvRadio from '../images/tvRadio.png'
import TICKET from '../images/620TheTicket.png'
import STATION from '../images/ThatStation-color_logo.png'
import BUZZ from '../images/The_Buzz_logo.png'

export default () => (
    <div id="app">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Contact Page"></meta>
          <title>TV & Radio Advertising | Raleigh | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/tv-radio/" />
        </Helmet>
      <PreHeader/>
      <Header/>
      <h1 className="tv-radio--title">TV & Radio</h1>
      <div className="tv-radio">
        <div className="tv-radio_grid">
        <div className="tv-radio-one">
        <div className="tv-radio_wrapper">
          <h2 className="tv-radio--subhead">TV & Radio in the 21st Century</h2>
          <p className="tv-radio_text">
          Capitol Broadcasting Company is a traditional media powerhouse in the Triangle and in Wilmington, NC.
          With multiple TV and radio properties in these areas we are able to help businesses reach their audience
          in other ways besides just digital. Additionally, the same people strategizing and selling digital campaigns
          can help with TV or Radio, allowing full Omni-Channel campaigns to be run smoothly and efficiently.
          </p>
          <p className="tv-radio_text">
          What many don’t realize is how well TV, Radio & digital marketing work together.
          CBC has years of experience in running large and small scale campaigns utilizing multiple mediums,
          and tracking the results.
          </p>
        </div>



        <img src = {tvRadio} className="tv-radio--image" alt = "camera woman with headphones" />




      <h3 className="tv-radio--subhead">Our TV and Radio properties include:</h3>

      <div class="tv-radio_icons">

        <div class="tv-radio_icons--icon WRAL">
          <a className="tv-radio_icon-image" href="http://www.capitolbroadcasting.com/divisions/television/wral-tv/" target="_blank" rel="noopener noreferrer">
            <img src = {WRAL} alt ="WRALTV logo"/>
          </a>
          <a href="http://www.capitolbroadcasting.com/divisions/television/wral-tv/" className="tv-radio_icon-title" target="_blank" rel="noopener noreferrer"><p>WRAL TV</p></a>
          <p className="tv-radio_icon-blurb">NBC Affiliate in Raleigh, NC</p>
        </div>

        <div class="tv-radio_icons--icon MIX">
          <a target="_blank"  rel="noopener noreferrer" className="tv-radio_icon-image" href="https://www.wralfm.com/">
            <img src = {MIX} alt ="MIX 101.5 wral-fm logo"/>
          </a>
          <a target="_blank"  rel="noopener noreferrer" href="http://www.wralfm.com" className="tv-radio_icon-title"><p>MIX101.5</p></a>
          <p className="tv-radio_icon-blurb">Variety Radio in Raleigh, NC</p>
        </div>

        <div class="tv-radio_icons--icon WILM">
          <a target="_blank"  rel="noopener noreferrer" className="tv-radio_icon-image" href="https://www.wilm-tv.com/">
            <img src = {WILM} alt ="WILM 10 logo"/>
          </a>
          <a target="_blank"  rel="noopener noreferrer" href="https://www.wilm-tv.com/" className="tv-radio_icon-title"><p>WILM</p></a>
          <p className="tv-radio_icon-blurb">Independent TV Station in Wilmington, NC</p>
        </div>

        <div class="tv-radio_icons--icon FOX50">
          <a target="_blank"  rel="noopener noreferrer" className="tv-radio_icon-image" href="https://www.capitolbroadcasting.com/divisions/television/fox-50/">
            <img src = {FOX} alt ="FOX50 logo"/>
          </a>
          <a target="_blank"  rel="noopener noreferrer" href="https://www.capitolbroadcasting.com/divisions/television/fox-50/" className="tv-radio_icon-title"><p>FOX 50</p></a>
          <p className="tv-radio_icon-blurb">Fox Affiliate in Raleigh, NC</p>
        </div>

        <div class="tv-radio_icons--icon THEFAN">
          <a target="_blank"  rel="noopener noreferrer" className="tv-radio_icon-image" href="https://www.wralsportsfan.com/">
            <img src = {THEFAN} alt ="99.9 THE FAN ESPN logo"/>
          </a>
          <a target="_blank"  rel="noopener noreferrer" href="https://www.wralsportsfan.com/" className="tv-radio_icon-title"><p>ESPN 99.9 THE FAN</p></a>
          <p className="tv-radio_icon-blurb">Sports Radio in Raleigh, NC</p>
        </div>

        <div class="tv-radio_icons--icon SUNRISE">
          <a target="_blank"  rel="noopener noreferrer" className="tv-radio_icon-image" href="https://www.capitolbroadcasting.com/divisions/radio/sunrise-broadcasting-stations/">
            <img src = {SUNRISE} alt ="Sunrise broadcasting logo"/>
          </a>
          <a target="_blank"  rel="noopener noreferrer" href="https://www.capitolbroadcasting.com/divisions/radio/sunrise-broadcasting-stations/" className="tv-radio_icon-title"><p>SUNRISE BROADCASTING</p></a>
          <p className="tv-radio_icon-blurb">Multiple Radio Stations in Wilmington, NC
WAZO, WILT, WKXB, WRMR, WMFD, WKXB-HD3</p>
        </div>

        <div class="tv-radio_icons--icon STATION">
          <a target="_blank"  rel="noopener noreferrer" className="tv-radio_icon-image" href="https://www.thatstation.net/">
            <img src = {STATION} alt ="95.7 FM That Station logo"/>
          </a>
          <a target="_blank"  rel="noopener noreferrer" href="https://www.thatstation.net/" className="tv-radio_icon-title"><p>95.7 FM That Station</p></a>
          <p className="tv-radio_icon-blurb">Americana Music in Raleigh, NC</p>
        </div>

        <div class="tv-radio_icons--icon BUZZ">
          <a target="_blank"  rel="noopener noreferrer" className="tv-radio_icon-image" href="https://www.capitolbroadcasting.com/divisions/radio/cbc-sports-radio/">
            <img src = {BUZZ} alt ="The Buzz logo"/>
          </a>
          <a target="_blank"  rel="noopener noreferrer" href="https://www.capitolbroadcasting.com/divisions/radio/cbc-sports-radio/" className="tv-radio_icon-title"><p>The Buzz</p></a>
          <p className="tv-radio_icon-blurb">Sports Radio in Raleigh, NC</p>
        </div>

        <div class="tv-radio_icons--icon TICKET">
          <a target="_blank"  rel="noopener noreferrer" className="tv-radio_icon-image" href="https://www.capitolbroadcasting.com/divisions/radio/cbc-sports-radio/">
            <img src = {TICKET} alt ="620 The Ticket logo"/>
          </a>
          <a target="_blank"  rel="noopener noreferrer" href="https://www.capitolbroadcasting.com/divisions/radio/cbc-sports-radio/" className="tv-radio_icon-title"><p>620 The Ticket</p></a>
          <p className="tv-radio_icon-blurb">Sports Radio in Raleigh, NC</p>
        </div>
        </div>

      </div>
      <div className="tv-radio-two">
      {/* <img src = {tvRadio} className="tv-radio--image" alt = "camera woman with headphones" /> */}
      </div>
      </div>
      <div className="tv-radio_banner">
          <h3 className="tv-radio_banner-text">
            To get started today <a href="tel:+1-919-744-3030">call</a> or fill out our contact form.
          </h3>
        <div className="tv-radio_cta">
            <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--mobile"/>
            <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--desktop"/>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
  );
